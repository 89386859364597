import { LockClosedIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';

import { getCampaignCookieSettings } from '@/app/campaigns/helpers';
import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { MastercardIcon } from '@/app/editor/blocks/components/PaymentForm/icons/MastercardIcon';
import { StripeIcon } from '@/app/editor/blocks/components/PaymentForm/icons/StripeIcon';
import { VisaIcon } from '@/app/editor/blocks/components/PaymentForm/icons/VisaIcon';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getColorByLightness, getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';

import Box from '../_wrapper/Box';
import Text from '../Text/Component';

import type { DefaultBlockComponentProps } from '../../types';
import type { JSONContent } from '@tiptap/core';

export interface Props extends DefaultBlockComponentProps {
    wysiwyg: JSONContent;
    color: string;
    currency: string;
    amountInText: string;
}

const Component = ({
    artBoardIndex,
    blockId,
    box,
    isDragged,
    isPreview,
    wysiwyg,
    activeTheme,
    color,
    currency,
    amountInText,
}: Props) => {
    const { isInColumn } = useLayoutInfo(blockId, isPreview);
    const campaign = useAppSelector(getActiveCampaign);
    const cookieSettings = getCampaignCookieSettings(campaign);
    const cookieLanguage = cookieSettings.language;

    const btnText =
        cookieLanguage === 'en'
            ? `Pay ${amountInText}${currency === 'eur' ? '€' : '$'} now`
            : `Jetzt ${amountInText}${currency === 'eur' ? '€' : '$'} bezahlen`;

    const btnStyles = useMemo(() => {
        const backgroundColor = getThemedOrCustomColor(color, 'buttonBackgroundColor', activeTheme);

        return {
            backgroundColor,
            color: getColorByLightness('transparent', backgroundColor, true),
        };
    }, [activeTheme, color]);

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            {/*Question Text*/}
            <Text
                align="center"
                blockId={blockId}
                wysiwyg={wysiwyg}
                color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
            />

            {/*Fake Inputs*/}
            <div className="mt-4 grid w-full grid-cols-4 gap-4 text-gray-400">
                <div className="col-span-full flex items-center justify-between rounded-md border bg-white p-4">
                    <span>{cookieLanguage === 'en' ? 'Card number' : 'Kartennummer'}</span>
                    <div className="flex items-center gap-1">
                        <div className="rounded-md border p-1">
                            <MastercardIcon />
                        </div>
                        <div className="rounded-md border p-1">
                            <VisaIcon />
                        </div>
                    </div>
                </div>
                <div className="col-span-full rounded-md border bg-white p-4">
                    {cookieLanguage === 'en' ? 'Name on card' : 'Name auf Karte'}
                </div>
                <div className="col-span-2 rounded-md border bg-white p-4">MM / YY</div>
                <div className="col-span-2 flex items-center justify-between rounded-md border bg-white p-4">
                    <span>CVC</span>
                    <InformationCircleIcon className="size-5" />
                </div>
                <div
                    className="col-span-full rounded-md p-4 text-center font-semibold text-white"
                    style={btnStyles}
                >
                    {btnText}
                </div>
                <div className="col-span-full flex items-center justify-center gap-2 px-4 text-sm">
                    <LockClosedIcon className="size-3" />
                    <span>{cookieLanguage === 'en' ? 'Secured by' : 'Gesichert von'}</span>
                    <StripeIcon />
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'PaymentForm';

export default Component;
