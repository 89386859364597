import { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';

import {
    getActivePageBlockIds,
    getBlockIndexInParent,
    getBlockIndexOnPage,
} from '@/app/editor/blocks/models/blockOrder';
import {
    getAllBlockParents,
    getBlockById,
    getBlocksByParent,
    setBlock,
    upsertBlockAtIndex,
} from '@/app/editor/blocks/models/blocks';
import { optimisticallyRemoveBlockSync } from '@/app/editor/blocks/models/delete';
import { BlockComponentType } from '@/app/editor/blocks/types';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { getResolveVirtualIdsInBlock } from '@/app/editor/engine/hooks/usePerspectiveNodeManager/getResolveVirtualIdsInBlock';
import { getRowLengthForComponent } from '@/app/editor/engine/utils/node/getRowLengthForComponent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { usePerspectiveEditorEngineVirtualIdMap } from './usePerspectiveEditorEngineVirtualIdMap';
import { areNodesInRowsOfNItemsContiguous } from '../../utils/node/areNodesInRowsOfNItemsContiguous';

import type { TRACKING_ID_FIELD_NAMES } from '../../constants';
import type { BlockResource } from '@/app/editor/blocks/types';
import type {
    ChangeNodePayload,
    EditorEngineNode,
    EditorEngineNodeData,
    EditorEngineNodeManager,
    EditorEngineNodeManagerHook,
    EditorEngineParentId,
    InsertNodeAtPayload,
    ReinsertAtIndexPayload,
    RemoveNodePayload,
} from '@/app/editor/engine/core/types';
import type {
    PerspectiveEditorEngineExtraContext,
    PerspectiveEditorEngineNodeData,
    PerspectiveEditorEngineNodeManagerProps,
} from '@/app/editor/engine/types';
import type { AppState } from '@/core/redux/types';

/**
 * Returns the Perspective Editor node manager.
 *
 * todo(editorengine): consider splitting this hook
 *
 * This hook is currently the interface between the editor engine and the redux
 * store, where all nodes are stored. Since node managers must fulfill a
 * specific contract, each of these methods is (in some way) getting data from
 * the redux store.
 *
 * This hook might change in the future. We might want to store nodes in a
 * different way, or we might only want to simplify the hook. At the moment, the
 * priority is that the editor engine reaches a stable enough state that would
 * allow us to slowly introduce further improvements.
 */
export const usePerspectiveNodeManager = (() => {
    const store = useStore<AppState>();
    const dispatch = useAppDispatch();
    const artboardSize = useArtboardSize();
    const { virtualIdMap, resolveVirtualId, mapVirtualIdToConcreteId } =
        usePerspectiveEditorEngineVirtualIdMap();

    const normalizeParentId = useCallback(
        (virtualId: EditorEngineParentId) => {
            if (virtualId === 'root') {
                return null;
            }

            return resolveVirtualId(virtualId);
        },
        [resolveVirtualId],
    );

    const makeParentIdOptionalObject = useCallback(
        <TObject extends object>(
            virtualId: EditorEngineParentId,
            objectGetter: (id: string) => TObject,
        ) => {
            const normalizedId = normalizeParentId(virtualId);

            return (normalizedId ? objectGetter(normalizedId) : {}) as TObject | {};
        },
        [normalizeParentId],
    );

    const resolveConcreteId = useCallback(
        (concreteId: string) => {
            return (
                Array.from(virtualIdMap.current.entries()).find(
                    ([, value]) => value === concreteId,
                )?.[0] ?? concreteId
            );
        },
        [virtualIdMap],
    );

    const getDebugRepresentation = ((node) => {
        if (!node || !node.block) {
            return {
                debugError: 'Invalid node',
            };
        }

        return {
            id: node.block.id,
            componentType: node.block.attributes.componentType,
        };
    }) satisfies EditorEngineNodeManager<PerspectiveEditorEngineNodeData>['getDebugRepresentation'];

    const getNode = useCallback(
        (nodeId: string) => {
            const block = getBlockById(store.getState(), nodeId);

            if (!block) {
                return null;
            }

            return {
                block,
            } satisfies PerspectiveEditorEngineNodeData;
        },
        [store],
    );

    const getBlock = useCallback(
        (id: string) => {
            return getNode(id)?.block ?? null;
        },
        [getNode],
    );

    const getParentId = useCallback((node: PerspectiveEditorEngineNodeData) => {
        return node.block.relationships.parent.data?.id ?? 'root';
    }, []);

    const getParentNode = useCallback(
        (node: EditorEngineNode<PerspectiveEditorEngineNodeData>) => {
            return getNode(getParentId(node));
        },
        [getNode, getParentId],
    );

    const getNodeIndex = useCallback(
        (id: string) => {
            const block = getBlock(id);

            if (!block) {
                return -1;
            }

            const parent = block.relationships.parent.data?.id ?? 'root';

            if (parent === 'root') {
                return getBlockIndexOnPage(store.getState(), id);
            }

            return getBlockIndexInParent(store.getState(), id);
        },
        [getBlock, store],
    );

    const getNodesByParent = useCallback(
        (parent: EditorEngineParentId) => {
            if (parent === 'root') {
                return getActivePageBlockIds(store.getState()).map((id) => ({
                    block: getBlock(id),
                }));
            }

            const children =
                getNode(parent)?.block.relationships.components.data.map(({ id }) =>
                    getBlock(id),
                ) ?? [];

            return children.map((block) => ({
                block,
            }));
        },
        [getNode, store, getBlock],
    );

    const useNodes = (parent: EditorEngineParentId) => {
        const nodes = useAppSelector((state) =>
            getBlocksByParent(state, parent === 'root' ? null : parent, resolveConcreteId),
        );

        return nodes.map((id) => ({
            block: getBlock(id),
        }));
    };

    // todo(editorengine): extract this to somewhere else when adding tests
    const getAsContiguous = useCallback(
        (node1, node2, orientation) => {
            if (
                node1.block.relationships.parent.data?.id !==
                node2.block.relationships.parent.data?.id
            ) {
                return false;
            }

            const node1RowLength = getRowLengthForComponent({
                component: node1.block,
                artboardSize,
            });
            const node2RowLength = getRowLengthForComponent({
                component: node2.block,
                artboardSize,
            });

            if (node1RowLength > 1 && node2RowLength > 1 && node1RowLength === node2RowLength) {
                return areNodesInRowsOfNItemsContiguous({
                    firstNode: node1,
                    secondNode: node2,
                    firstNodeIndex: getBlockIndexInParent(store.getState(), node1.block.id),
                    secondNodeIndex: getBlockIndexInParent(store.getState(), node2.block.id),
                    orientation,
                    length: node1RowLength,
                });
            }

            if (orientation === EditorEngineOrientation.Horizontal) {
                // In this orientation, columns can be contiguous
                // List items in the desktop view were already checked

                if (
                    node1.block.attributes.componentType !== BlockComponentType.GRID_COLUMN ||
                    node2.block.attributes.componentType !== BlockComponentType.GRID_COLUMN
                ) {
                    return false;
                }
            } else {
                // In this orientation, columns are never contiguous

                if (
                    node1.block.attributes.componentType === BlockComponentType.GRID_COLUMN ||
                    node2.block.attributes.componentType === BlockComponentType.GRID_COLUMN
                ) {
                    return false;
                }
            }

            let node1Index: number;
            let node2Index: number;

            if (!node1.block.relationships.parent.data) {
                // Top level blocks

                if (
                    node1.block.relationships.page.data?.id !==
                    node2.block.relationships.page.data?.id
                ) {
                    return false;
                }

                node1Index = getBlockIndexOnPage(store.getState(), node1.block.id);
                node2Index = getBlockIndexOnPage(store.getState(), node2.block.id);
            } else {
                node1Index = getBlockIndexInParent(store.getState(), node1.block.id);
                node2Index = getBlockIndexInParent(store.getState(), node2.block.id);
            }

            if (node1Index + 1 === node2Index) {
                return [node1, node2] as [
                    EditorEngineNode<PerspectiveEditorEngineNodeData>,
                    EditorEngineNode<PerspectiveEditorEngineNodeData>,
                ];
            }

            if (node2Index + 1 === node1Index) {
                return [node2, node1] as [
                    EditorEngineNode<PerspectiveEditorEngineNodeData>,
                    EditorEngineNode<PerspectiveEditorEngineNodeData>,
                ];
            }

            return false;
        },
        [artboardSize, store],
    ) satisfies EditorEngineNodeManager<PerspectiveEditorEngineNodeData>['getAsContiguous'];

    const isFirstInOrientation = useMemo(() => {
        return ((node, orientation) => {
            if (
                !node.block.relationships.parent.data &&
                orientation === EditorEngineOrientation.Horizontal
            ) {
                // In this orientation top level blocks are always first
                return true;
            }

            const nodeRowLength = getRowLengthForComponent({
                component: node.block,
                artboardSize,
            });

            if (nodeRowLength > 1) {
                if (orientation === EditorEngineOrientation.Horizontal) {
                    return getNodeIndex(node.block.id) % nodeRowLength === 0;
                }

                return Array.from({ length: nodeRowLength }, (_, i) => i).includes(
                    getNodeIndex(node.block.id),
                );
            }

            if (orientation === EditorEngineOrientation.Horizontal) {
                // In this orientation, everything but columns are first

                if (node.block.attributes.componentType !== BlockComponentType.GRID_COLUMN) {
                    return true;
                }
            } else {
                // In this orientation, columns are always first

                if (node.block.attributes.componentType === BlockComponentType.GRID_COLUMN) {
                    return true;
                }
            }

            return getNodeIndex(node.block.id) === 0;
        }) satisfies EditorEngineNodeManager<PerspectiveEditorEngineNodeData>['isFirstInOrientation'];
    }, [artboardSize, getNodeIndex]);

    const isAncestor = useCallback(
        ({ nodeId, potentialAncestorId }: { nodeId: string; potentialAncestorId: string }) => {
            const allParents = getAllBlockParents(store.getState(), nodeId);

            return allParents.some((parent) => parent.id === potentialAncestorId);
        },
        [store],
    );

    const makeNode = useCallback(
        (
            id: string,
            parent: EditorEngineParentId,
            data: Omit<PerspectiveEditorEngineNodeData, keyof EditorEngineNodeData>,
        ) => {
            return {
                block: data.block,
            } satisfies EditorEngineNode<PerspectiveEditorEngineNodeData>;
        },
        [],
    );

    const insertNodeAt = useCallback(
        ({ node, parentId, index }: InsertNodeAtPayload<PerspectiveEditorEngineNodeData>) => {
            dispatch(
                upsertBlockAtIndex(
                    // todo(editorengine): this thing below could be moved to a utility and improved
                    {
                        ...node.block,
                        relationships: {
                            ...node.block.relationships,
                            parent: {
                                data:
                                    parentId === 'root'
                                        ? null
                                        : {
                                              type: 'component',
                                              id: parentId,
                                          },
                            },
                        },
                    },
                    index,
                ),
            );
        },
        [dispatch],
    );

    const removeNode = useCallback(
        ({ id }: RemoveNodePayload) => {
            let block = getBlock(id);

            // Note: using the sync version. Read more in the function definition.
            dispatch(optimisticallyRemoveBlockSync(block));
        },
        [dispatch, getBlock],
    );

    const changeNode = useCallback(
        ({ id, newDataGetter }: ChangeNodePayload<PerspectiveEditorEngineNodeData>) => {
            const node = getNode(id);
            const block = node?.block ?? null;

            if (!block) {
                return;
            }

            const newData = newDataGetter(node);

            if (!('block' in newData)) {
                return;
            }

            dispatch(setBlock(newData.block));
        },
        [dispatch, getNode],
    );

    const reinsertAtIndex = useCallback(
        ({ id, newParentId, newIndex }: ReinsertAtIndexPayload) => {
            const block = getBlock(id);

            if (!block) {
                return;
            }

            const oldParentId = block.relationships.parent.data?.id ?? 'root';

            removeNode({ id });
            insertNodeAt({
                node: {
                    block,
                },
                parentId: newParentId ? newParentId : oldParentId,
                index: newIndex,
            });
        },
        [getBlock, insertNodeAt, removeNode],
    );

    const resolveVirtualIdsInBlock = useMemo(() => {
        return getResolveVirtualIdsInBlock({
            resolveVirtualId,
        });
    }, [resolveVirtualId]);

    const updateBlockAttributes = useCallback(
        (blockId: string, attributes: BlockResource['attributes']) => {
            changeNode({
                id: blockId,
                newDataGetter: (data) => ({
                    ...data,
                    block: {
                        ...data.block,
                        attributes: {
                            ...data.block.attributes,
                            ...attributes,
                        },
                    },
                }),
            });
        },
        [changeNode],
    );

    const updateTrackingId = useCallback(
        (blockId: string, key: (typeof TRACKING_ID_FIELD_NAMES)[number], value: string) => {
            changeNode({
                id: blockId,
                newDataGetter: (data) => ({
                    ...data,
                    block: {
                        ...data.block,
                        attributes: {
                            ...data.block.attributes,
                            content: {
                                ...data.block.attributes.content,
                                [key]: value,
                            },
                        },
                    },
                }),
            });
        },
        [changeNode],
    );

    const baseImplementation = {
        getDebugRepresentation,
        identify: (node: EditorEngineNode<PerspectiveEditorEngineNodeData>) => {
            return node?.block?.id;
        },
        getNode,
        getParentId,
        getNodeIndex,
        getNodesByParent,
        useNodes,
        getAsContiguous,
        isFirstInOrientation,
        isAncestor,
        makeNode,
        insertNodeAt,
        removeNode,
        changeNode,
        reinsertAtIndex,
    } satisfies EditorEngineNodeManager<PerspectiveEditorEngineNodeData>;

    return {
        ...baseImplementation,
        getParentNode,
        mapVirtualIdToConcreteId,
        resolveVirtualId,
        resolveVirtualIdsInBlock,
        normalizeParentId,
        makeParentIdOptionalObject,
        updateBlockAttributes,
        updateTrackingId,
    };
}) satisfies EditorEngineNodeManagerHook<
    PerspectiveEditorEngineNodeData,
    PerspectiveEditorEngineExtraContext,
    PerspectiveEditorEngineNodeManagerProps
>;
